import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const IndexPage = () => (
  <>
    <Layout indexPage={true}>
      <Seo title="Home" />
      <div className="hero">
        <div className="heroText">
          <h1>
            Specializing in Adult & Elderly Residential services for individuals
            with developmental disabilities
          </h1>
          <AniLink
            paintDrip
            hex="#003861"
            to="facilities"
            className="ctaButton"
          >
            View our Facilities ➔
          </AniLink>
        </div>
      </div>
    </Layout>
  </>
)

export default IndexPage
